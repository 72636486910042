import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Section } from '../components/containers';
import SanityBlockContent from '@sanity/block-content-to-react';

export default function Page({ data }) {
  const page = data.sanityLegals;
  const { title, content } = page;

  return (
    <Layout template='legals'>
      <SEO title={title} />
      <Section halign='left'>
        <SanityBlockContent blocks={content._rawContent} />
      </Section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($id: String!) {
    sanityLegals(_id: { eq: $id }) {
      title
      content {
        _rawContent
      }
    }
  }
`;
